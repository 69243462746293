<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="cancelPacketsReceiveProcess" />
      </template>

      <template v-slot:title>
        <h1>{{ $t("packetsScanned.received") }}</h1>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="container">
        <div class="text-center list" v-if="emptyPackets">
          <h1>{{ $t("packetsScanned.no-packets") }}</h1>
        </div>
        <Errors :errors="errors" />
        <div v-for="(packetItem, index) in packets" class="item" :key="index">
          <check-icon
            v-if="packetItem.processResult === 'success'"
            class="check-icon green"
          />
          <close v-else class="close red" />
          <div class="flex-group">
            <h3>{{ packetItem.packet.barcode }}</h3>
            <p class="smaller">
              {{ packetItem.packet.name }} {{ packetItem.packet.surname }}
            </p>
          </div>
          <!--TODO STATE-->
          <p
            v-if="packetItem.processResult === 'success'"
            class="state smaller"
          >
            {{ packetItem.packet.status.name }}
          </p>
          <p
            v-else-if="packetItem.reason === 'packetAlreadyConsigned'"
            class="state smaller red"
          >
            {{ $t("packetsScanned.packetAlreadyConsigned") }}
          </p>
          <p
            v-else-if="packetItem.reason === 'packetAlreadyDelivered'"
            class="state smaller red"
          >
            {{ $t("packetsScanned.packetAlreadyDelivered") }}
          </p>
          <p
            v-else-if="packetItem.reason === 'packetAlreadyReceived'"
            class="state smaller red"
          >
            {{ $t("packetsScanned.packetAlreadyReceived") }}
          </p>
          <p
            v-else-if="packetItem.reason === 'packetCancelled'"
            class="state smaller red"
          >
            {{ $t("packetsScanned.packetCancelled") }}
          </p>
          <p v-else class="state smaller red">
            {{ $t("packetsScanned.unknown") }}
          </p>
        </div>
      </section>
    </main>
    <footer ref="footer">
      <div class="container">
        <a
          class="btn btn-primary btn-white"
          @click.prevent="printLabel"
          v-if="!emptyPackets && reprintId"
          >{{ $t("packetsScanned.print") }}</a
        >
        <a
          class="btn btn-primary btn-green"
          @click.prevent="cancelPacketsReceiveProcess"
          >{{ $t("packetC2Creceive.finish") }}</a
        >
      </div>
    </footer>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import CheckIcon from "vue-material-design-icons/Check.vue";
import Close from "vue-material-design-icons/Close.vue";
import { MOBILE_TYPES } from "@/util/mobile.type";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import Spinner from "@/components/Spinner";

export default {
  name: "PacketsReceiveProcess",
  mixins: [setActualFooterHeight, getParams],
  components: { Errors, AppHeader, CheckIcon, Close, Spinner },
  data() {
    return {
      batchId: this.$route.params.batchId
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    next();
  },

  async mounted() {
    await this.processPacketsReceive(this.batchId);
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("scanned", ["packets", "reprintId"]),

    emptyPackets() {
      return _.isEmpty(this.packets);
    }
  },

  methods: {
    ...mapActions("scanned", ["processPacketsReceive"]),
    ...mapActions("packet", ["reprintLabel"]),
    ...mapMutations(["clearErrors"]),

    cancelPacketsReceiveProcess() {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.backToScan();
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.backToScan.postMessage({});
        }
      } else {
        this.$router.replace({
          name: "dashboard",
          query: {
            platform: this.platform,
            device: this.device
          }
        });
      }
    },

    async printLabel() {
      await this.reprintLabel(this.reprintId);
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  .list {
    margin-top: 30px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px;
    border-bottom: 1px solid $grey;

    p {
      color: $light-grey;
    }

    .flex-group {
      margin-right: auto;
    }

    .check-icon,
    .close {
      margin-right: 9px;
    }

    .state {
      align-self: flex-start;
    }

    .red {
      color: $orange-red;
    }
  }
}

footer {
  a:first-child {
    margin-bottom: 10px;
  }
}
</style>
